<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between">
      <div class="col-auto px-0">
        <mdb-btn
          flat
          dark-waves
          icon="arrow-left"
          type="button"
          title="Regresar"
          @click="$router.go(-1)"
        >
          Regresar
        </mdb-btn>
      </div>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-md-4">
          <span class="small-block font-titulo">Nombre del reporte</span>
          <p>
            Kilos vendidos por mes del vendedor
          </p>
        </div>
        <div class="col-12 col-sm">
          <span class="small-block font-titulo">Mes</span>
          <p>
            {{ fechaMes }}
          </p>
        </div>
        <div class="col-12">
          <span class="small-block font-titulo">Descripción</span>
          <p class="mb-1">
            Reporte de los kilos vendidos por mes
            <span v-if="!vendedoresSeleccionados.length">de todos los vendedores.</span>
            <span v-else-if="vendedoresSeleccionados.length >= 2" class="d-inline">
              de los vendedores:
              <span v-for="(vende, index) in vendedoresSeleccionados" :key="index" class="text-capitalize">
                {{vende.nom_per + ' ' + vende.ape_per + (index === (vendedoresSeleccionados.length -1) ? '. ' : ', ')}}
              </span>
            </span>
            <span v-else>
              del vendedor {{ vendedoresSeleccionados[0].nom_per + ' ' + vendedoresSeleccionados[0].ape_per }}.
            </span>
          </p>
          <p class="mb-1">Muestra la comparativa del peso total de productos vendidos en la fecha seleccionada por el o los vendedores.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3">
        <mdb-input
          v-model="fechaMes"
          type="month"
          label="Mes"
          class="my-2"
          outline
        />
      </div>
      <div class="col-12 col-md px-0">
        <FiltrosTipo1
          :responsive="responsive"
          :ven="vendedores"
          :mostrar-btn-restablecer="true"
          @vendedorAFiltrar="vendedoresFiltrados = $event; actualizar()"
          @eliminarFiltros="restablecerFiltros()"
        />
      </div>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <!-- Mensaje cuando el vendedor seleccionado no tiene pedido -->
    <div
      v-else-if="!productos.length"
      class="mensaje-no-items pb-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay productos vendidos según los parámetros seleccionados</p>
    </div>
    <div v-else>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'mx-auto'"
      />
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>
<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'
import { apiPost } from '@/funciones/api.js'
import { convertirNumeroAFixed } from '@/funciones/funciones.js'
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'KilosVendidos',
  components: {
    mdbInput,
    mdbBtn,
    FiltrosTipo1,
    AlertaMensaje,
    LoaderKel,
    Bar
  },
  data () {
    return {
      cargando: false,
      productos: [],
      responsive: { btnRestablecer: 'col-12 col-md-auto' },
      vendedores: [],
      vendedoresFiltrados: [],
      vendedoresSeleccionados: [],
      fechaMes: (new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)).toString(),
      alertaMensaje: { contenido: '' },
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }
    }
  },
  mounted () {
    this.obtenerVendedoresFiltros()
    this.actualizar()
  },
  watch: {
    vendedoresFiltrados () {
      this.vendedoresSeleccionados = []
      this.agruparVendedoresSeleccionados()
    },
    fechaMes () {
      if (this.vendedoresFiltrados) {
        this.actualizar()
      }
    }
  },
  methods: {
    agruparVendedoresSeleccionados () {
      this.vendedoresFiltrados.forEach(IdVende => {
        this.vendedores.forEach(vendefiltrado => {
          return vendefiltrado.id_per === IdVende ? this.vendedoresSeleccionados.push(vendefiltrado) : null
        })
      })
    },
    obtenerVendedoresFiltros () {
      apiPost({ s: 'vendedores' }, '')
        .then((res) => {
          this.vendedores = res.data.ven
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ocurrió un error obteniendo los vendedores',
            tipo: 'error'
          }
        })
    },
    restablecerFiltros () {
      this.vendedoresFiltrados = []
      this.actualizar()
    },
    datosDelChart (productos, vendedores) {
      const labels = []
      const dataProductos = []
      const vendedoresKilos = vendedores
      const pesosPorVendedor = []
      vendedoresKilos.forEach(vende => {
        productos.forEach(pro => {
          if (pro.id_per_v_suc === vende.id_per) {
            vende.kilos = pro.kilos
          }
          if (!pesosPorVendedor[pro.id_per_v_suc] && pro.id_per_v_suc === vende.id_per) {
            pesosPorVendedor.push(pro.kilos)
          } else {
            pesosPorVendedor.push('')
          }
        })
      })
      pesosPorVendedor.sort((a, b) => ~~b - ~~a)
      vendedoresKilos.sort((a, b) => ~~b.kilos - ~~a.kilos)

      dataProductos.push({
        label: '',
        backgroundColor: this.generarColorHexadecimal(),
        data: pesosPorVendedor
      })
      vendedoresKilos.forEach(ven => {
        labels.push(`${ven.nom_per}-${ven.ape_per}. Total: ${this.convertirValorAFixed(ven.kilos) || 0} kg`)
      })

      this.chartData.labels = labels
      this.chartData.datasets = dataProductos
    },
    actualizar () {
      this.cargando = true
      let data = `y=${this.fechaMes.split('-')[0]}&m=${this.fechaMes.split('-')[1]}`
      data += this.vendedoresFiltrados ? '&vendedores=' + this.vendedoresFiltrados.join(',') : ''
      apiPost({ s: 'provsvenMv' }, data)
        .then((res) => {
          this.productos = res.data.pvv
          this.vendedoresFiltrados.length
            ? this.datosDelChart(this.productos, this.vendedoresSeleccionados)
            : this.datosDelChart(this.productos, this.vendedores)
          this.cargando = false
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ocurrió un error obteniendo los datos',
            tipo: 'error'
          }
        })
    },
    generarColorHexadecimal () {
      var color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      return color
    },
    convertirValorAFixed (numero) {
      return convertirNumeroAFixed(numero)
    }
  }
}
</script>
<style lang="scss">
.table.reporte-kilos {
  @include ul-table-responsive-at ("576px");
}
</style>
